<template>
  <b-form-group
    label="Gayrimenkuller"
    label-for="itemid"
  >
    <v-select
      id="itemid"
      v-model="itemData.id_estates"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
      :close-on-select="false"
      multiple
    >
      <template v-slot:option="option">
        <div>
          {{ option.title }}
        </div>
        <div>
          <b-badge
            v-if="option.segment"
            variant="light-success"
          >
            <feather-icon icon="InboxIcon" />
            {{ option.segment }}
          </b-badge>
          <b-badge
            variant="light-secondary"
            class="ml-1"
          >
            <feather-icon icon="MapIcon" />
            {{ option.district }}
          </b-badge>
          <b-badge
            variant="light-primary"
            class="ml-1"
          >
            <feather-icon icon="MapPinIcon" />
            {{ option.city }}
          </b-badge>
          <b-badge
            v-if="option.delivery_method"
            variant="light-warning"
            class="ml-1"
          >
            <feather-icon icon="InboxIcon" />
            {{ option.delivery_method }}
          </b-badge>
        </div>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup, BBadge } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'BulletinEstatesSelect',
  components: {
    BFormGroup,
    vSelect,
    BBadge,
  },
  computed: {
    dataList() {
      return this.$store.getters['estates/dataList']
    },
    itemData() {
      return this.$store.getters['bulletins/dataItem']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('estates/getDataList')
    },
  },
}
</script>
