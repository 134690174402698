<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <title-text />
        </b-col>
        <b-col cols="12">
          <bulletin-template-select-card />
        </b-col>
        <b-col
          v-if="itemData.id_bulletin_config === '2'"
          cols="12"
        >
          <bulletin-estates-select />
        </b-col>
        <b-col
          v-if="itemData.id_bulletin_config === '3'"
          cols="12"
        >
          <bulletin-reports-select />
        </b-col>
        <b-col
          v-if="itemData.id_bulletin_config === '3'"
          cols="12"
        >
          <bulletin-content />
        </b-col>
        <b-col
          v-if="itemData.id_bulletin_config === '3'"
          cols="12"
        >
          <bulletin-content-en />
        </b-col>
        <b-col cols="12">
          <content-text />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <template v-if="itemData.id_bulletin_config === '1'">
        <b-col
          v-if="itemData.template"
          md="9"
        >
          <bulletin-demo-card />
        </b-col>
        <b-col>
          <bulletin-image-card />
        </b-col>
      </template>
      <template v-else>
        <b-col>
          <bulletin-demo-card v-if="itemData.template" />
        </b-col>
      </template>

    </b-row>
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'

import { required } from '@validations'

import TitleText from '@/views/Admin/Bulletins/elements/TitleText.vue'
import ContentText from '@/views/Admin/Bulletins/elements/TemplateText.vue'
import BulletinTemplateSelectCard from '@/views/Admin/Bulletins/elements/BulletinTemplateSelect.vue'
import BulletinImageCard from '@/views/Admin/Bulletins/elements/ImageCard.vue'
import BulletinDemoCard from '@/views/Admin/Bulletins/elements/demoVue.vue'
import BulletinEstatesSelect from '@/views/Admin/Bulletins/elements/EstatesSelect.vue'
import BulletinReportsSelect from '@/views/Admin/Bulletins/elements/ReportsSelect.vue'
import BulletinContent from '@/views/Admin/Bulletins/elements/ContentText.vue'
import BulletinContentEn from '@/views/Admin/Bulletins/elements/ContentEnText.vue'

export default {
  name: 'ItemForm',
  components: {
    BulletinContentEn,
    BulletinContent,
    BulletinReportsSelect,
    BulletinEstatesSelect,
    BulletinDemoCard,
    BulletinImageCard,
    BulletinTemplateSelectCard,
    BCard,
    BRow,
    BCol,
    BButton,
    TitleText,
    ContentText,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['bulletins/dataItem']
    },
  },
  mounted() {
    this.itemData.submitStatus = false
  },
}
</script>
