<template>
  <b-form-group
    label="Raporlar"
    label-for="itemid"
  >
    <v-select
      id="itemid"
      v-model="itemData.id_reports"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
    >
      <template v-slot:option="option">
        <div>
          {{ option.title }}
        </div>
        <b-badge
          v-if="option.sub_title"
          variant="light-secondary"
        >
          <feather-icon icon="CornerDownRightIcon" />
          {{ option.sub_title }}
        </b-badge>
        <b-badge
          v-if="option.year"
          variant="light-primary"
          class="ml-1"
        >
          <feather-icon icon="CalendarIcon" />
          {{ option.year }}
        </b-badge>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { BBadge, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'BulletinReportsSelect',
  components: {
    BBadge,
    BFormGroup,
    vSelect,
  },
  computed: {
    dataList() {
      return this.$store.getters['reports/dataList']
    },
    itemData() {
      return this.$store.getters['bulletins/dataItem']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('reports/getDataList')
    },
  },
}
</script>
