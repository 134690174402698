<template>
  <b-form-group
    label="Bülten İçerik Kodu"
    label-for="content"
  >
    <b-form-textarea
      v-model="dataItem.template"
      placeholder="Bülten kaydını oluşturduğunuzda burada bülten kodu görüntülenir."
      readonly
    />
    <div>
      <small class="text-primary">Yukarıdaki alandaki oluşturulan kodu bülten gönderiminde kullanabilirsiniz.</small>
      <div class="text-right">
        <b-button
          variant="primary"
          :disabled="!dataItem.template"
          @click="clipboardCopy"
        >
          <feather-icon icon="CopyIcon" />
          Bülten kodunu kopyala
        </b-button>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Content',
  components: {
    BFormGroup,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      required,
      editorOption: {
        modules: {
          toolbar: '#content_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['bulletins/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    clipboardCopy() {
      navigator.clipboard.writeText(this.dataItem.template)
        .then(() => {
          this.showToast({
            variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: 'Bülten kodu başarı ile kopyalandı',
          })
        })
        .catch(() => {
          this.showToast({
            variant: 'danger', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: 'Bülten kodu kopyalanamadı.',
          })
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
