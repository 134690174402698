<template>
  <b-card
    title="İçerik Önizlemesi"
  >
    <!--    <div v-html="dataItem.template" />-->
    <iframe
      :src="$store.state.app.baseURL +'/theme/getBulletinSchema?id_bulletins=' +dataItem.id"
      width="100%"
      height="800px"
      frameborder="0"
      allowfullscreen
    />
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'BulletinDemoCard',
  components: {
    BCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['bulletins/dataItem']
    },
  },
}
</script>
